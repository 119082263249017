@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600&display=swap');

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

header.bar {

	opacity: .95;


}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}


/* colors-
color: #1b9696;
background-color: #2C3333;
color: #415e64;
background-color: #0E8388;
border-color: #023c53;
background-color: #0E8388; */

div.appointments {
	display: inline-flex;
	width: 80vw;
	height: 70lh;
}

body {

	margin: 0;
	padding: 0;
	background-image: url('images/hasmat.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	font-family: "Oswald";
}

/* Your existing styles... */

.TOSPRIVACY {
	display: inline-block;
	border-style: solid;
	border-color: #021f30;
	background-color: rgba(27, 181, 186, .3);
	text-align: center;
	align-self: center;
	font-weight: bold;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	width: auto;
	height: auto;
	border-top-style: solid;
	border-right-style: solid;
	cursor: pointer;
	border-bottom-style: solid;
	border-left-style: solid;
	color: rgb(255, 255, 255, .8);
	background-color: rgba(27, 181, 186, .3);
	list-style: none;
	margin: .6%;
	padding-left: .5%;
	padding-right: .5%;
	padding-bottom: 1%;
	border-radius: 4%;
	text-align: center;
	align-self: left;
	font-weight: bold;
  }
  .LogReg {
	/* display: inline-block;
	border-style: solid;
	border-color: #04263a;

	text-align: center;
	align-self: center;
	font-weight: bold;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	 Padding decides how big box 
	height: max-content;
	width: max-content;
	border-top-style: solid;
	border-right-style: solid;
	cursor: pointer;
	border-bottom-style: solid;
	border-left-style: solid;
	color: rgb(255, 255, 255, .8);
	background-color: rgba(27, 181, 186, .3);
	list-style: none;
	margin-top: .75%;
	padding: auto;
	border-radius: 4%;
	text-align: center;
	align-self: left; 
	*/

	display: inline-block;
	font: inherit;
	align-self: flex-start;
	font-size-adjust: inherit;
	flex-grow: inherit;
	align-self: inherit;
	text-align: center;
	size-adjust: inherit;
	transition: all 0.3s ease;
	/* height: auto;
	width: auto; */
	border-top-style: solid;
	border-right-style: solid;
	cursor: pointer;
	border-bottom-style: solid;
	border-left-style: solid;
	color: rgb(255, 255, 255, .8);
	background-color: rgba(27, 181, 186, .3);
	padding-block:inherit;
	list-style: none;
	margin-top: .75%;
	padding: auto;
	border-radius: 4%;
	text-align: center;
	align-self: left;
	
	font-weight:bolder;
  }
  .topnav button.split{
	display: inline-block;
	font: inherit;
	align-self: flex-start;
	font-size-adjust: inherit;
	flex-grow: inherit;
	align-self: inherit;
	text-align: center;
	size-adjust: inherit;
	transition: all 0.3s ease;
	/* height: auto;
	width: auto; */
	border-top-style: solid;
	border-right-style: solid;
	
	border-bottom-style: solid;
	border-left-style: solid;
	color: rgb(255, 255, 255, .8);
	background-color: rgba(27, 181, 186, .3);
	
	list-style: none;

	border-color:  rgb(255, 255, 255, .8);
	list-style: none;
	margin: inherit;
	padding: auto;
	border-radius: 4%;
	text-align: center;
	align-self: left;
	font-weight:bolder;
	
	/* display: inline-block;
	position: relative;
	margin-right: 0;
	right: 0;
	float: right;
	
	width: auto;
	justify-self: right;
	
	height: max-content;
	width: max-content;

	border-top-style: solid;
	border-right-style: solid;
	
	border-bottom-style: solid;
	border-left-style: solid;
	
	color:rgba(27, 181, 186, .3); 
	background-color:rgb(255, 255, 255, .8);
	list-style: none;
	margin-top: .75%;
	padding: auto;
	border-radius: 4%;
	text-align: center;
	align-self: left;
	font-weight:bolder;
	border-bottom-style: solid; */
  }
  
  .topnav img {
	display: inline-flex;
	position: relative;
	align-self: left;
	height: 9lvh;
	width: min-content;
	padding: inherit;
	overflow: hidden;
	justify-content: inherit;
	border-style: none;
	margin: inherit;
	justify-self: flex-start;
	left: 0;
	opacity: .7;
	transition: .1s ease;
	backface-visibility: hidden;
  }
  
  .topnav {
	position: relative;
	top: 0;
	overflow-y: hidden;
	left: 0;
	
	justify-content:stretch;
	padding-inline:inherit;
	display: inline-flex;
	flex-wrap: nowrap;
	flex-flow: row;
	flex-shrink: inherit;
	overflow:hidden;
	justify-content: flex-start;
	height: auto;
	width: 100%;
	align-items: left;
	justify-content: left;
	background-image: url('images/background.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
  }
  
  .topnav table {
	display: inline-flex;
	position: relative;
	width: 100%;
	flex-flow:nowrap;
	flex-direction: column-reverse;
	/* flex: content;
	flex-basis: inherit; */
	justify-items: stretch;
	justify-content: space-between;
	margin-left: 1%;
	height: auto;

	margin-top: auto;
	margin-bottom: auto;
	float: left;
	flex-direction: row;
	justify-self: left;
	flex-flow: row;
	left: 0;
  }
  
  .topnav td {
	justify-self: left;
	scroll-padding-block:inherit;
	float: left;
	position: relative;
	justify-self:stretch;
	justify-content: stretch;
	size-adjust: inherit;
	flex:inherit;
	flex-basis: auto;
	flex-grow: inherit;
	flex-shrink: inherit;
	display: inline-flex;
	width: auto;
	height: fit-content;
	align-self: left;
	list-style: none;
	margin:inherit;
	padding: auto;
  }
  
  .topnav td.split {
	position: relative;
	height: fit-content;
	display: flex;
	flex-flow: row;
	width: auto;

	justify-self:flex-start;
	justify-self: stretch;
	flex-basis: inherit;
	justify-self: right;
	float: right;
	margin: auto;
  }
  
  .topnav tr {
	display: flex;
	float: right;
	flex-direction: column-reverse;
	flex-flow: row-reverse;
	margin: auto;
	flex: content;
	justify-content: space-evenly;
	padding:inherit;
	justify-self: right;
	align-items: center;
	justify-content: right;
	margin: auto;
	float: right;
	right: 0;
  }
  .topnav.lg tr {
	display: flex;
	float: right;
	flex-direction: column-reverse;
	flex-flow: row;
	margin: auto;
	flex: content;
	justify-content: space-evenly;
	padding:inherit;
	justify-self: right;
	align-items: center;
	justify-content: right;
	margin: auto;
	float: right;
	right: 0;
  }
  
  .topnav.forgot {
	display: inline-block;
	position: relative;
	margin-right: 0;
	right: 0;
	float: right;
	
	width: auto;
	justify-self: right;
	
	height: max-content;
	width: max-content;

	border-top-style: solid;
	border-right-style: solid;
	
	border-bottom-style: solid;
	border-left-style: solid;
	
	color: rgb(255, 255, 255, .8);
	background-color: rgba(27, 181, 186, .3);
	list-style: none;
	margin-top: .75%;
	padding: auto;
	border-radius: 4%;
	text-align: center;
	align-self: left;
	font-weight:bolder;
	border-bottom-style: solid;
  }
  

  .butt{
	display: inline-block;
	font: inherit;
	align-self: flex-start;
	font-size-adjust: inherit;
	flex-grow: inherit;
	align-self: inherit;
	text-align: center;
	size-adjust: inherit;
	transition: all 0.3s ease;
	/* height: auto;
	width: auto; */
	border-top-style: solid;
	border-right-style: solid;
	cursor: pointer;
	border-bottom-style: solid;
	border-left-style: solid;
	color: rgb(255, 255, 255, .8);
	background-color: rgba(27, 181, 186, .3);

	list-style: none;
	margin: inherit;
	padding: auto;
	border-radius: 4%;
	text-align: center;
	align-self: left;
	font-weight:bolder;
  }
  
  .topnav img:hover {
	opacity: .3;
	
	background-color: rgb(19, 214, 221);
	cursor: pointer;
	background-size: cover;

	border-style: line;
	padding:inherit;
	
  }
  
  /* Change the color of links on hover */
  .butt:hover {
	background-color: rgb(94, 255, 255,.6);
	color: #09307d;
	border-color:  #09307d;
  }
  
  .topnav.forgot:hover {
	background-color: rgba(94, 255, 255, 0.803);
	color: #09307d;
	border-color:  #09307d;
	font-weight: bold;
  }

  .topnav button.split:hover {
	background-color: rgba(94, 255, 255, 0.803);
	color: #09307d;
	border-color:  #09307d;
	font-weight: bold;
  }
  
  /* Create a right-aligned (split) link inside the navigation bar */
  .topnav button.split {
	cursor: pointer;
	align-self: right;
	font-weight: bold;
	background-color: rgba(27, 181, 186, .3);
	color: rgb(255, 255, 255, .8);
  }
  
  /* New styles for links with class TOSPRIVACY */
  .TOSPRIVACY:hover {
	background-color: #6fc6c9;
	color: #2f0606;
  }
  .LogReg:hover {
	
	background-color: rgb(94, 255, 255,.6);
	color: #09307d;
	border-color:  #09307d;
  }




input[type=email],
input[type=name],
input[type=tel],
input[type=password] {
	width: 20%;
	justify-self: center;
	padding: 12px 40px;
	margin: 8px 0;
	display: inline-block;
	border: 1px solid #ccc;
	box-sizing: border-box;
}

div.login {
	align-self: center;
	align-content: center;
	justify-content: center;

}

label {
	justify-self: left;

}

button.login {
	background-color: #1bb5ba;
	color: white;
	padding: 14px 20px;
	margin: 8px 0;
	border: none;
	cursor: pointer;
	width: 100%;
	padding: 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	font-weight: bold;
	border-radius: 16px;
	width: 16%;
}

button.register {
	background-color: #1bb5ba;
	color: white;
	padding: 14px 20px;

	border: none;
	cursor: pointer;
	width: 100%;
	padding: 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;

	font-size: 16px;
	
	font-weight: bold;
	border-radius: 14.8px;
	width: fit-content;
	margin-bottom: 6%;
}

.fontpassword i {
	position: absolute;
	left: 15px;
	top: 40px;
	color: gray;
	position: relative;
}

div.tospriv{
	align-content: center;
	align-self: center;
	justify-self: center;
}
/* div.botSplit{
	float: right;
	bottom:0px;
	display: block;
	position: relative;

}; */

/* Style the links inside the navigation bar */


/* button.forgotCred{
	margin-top: .75%;
	cursor: pointer;
	float:right;
	
	border-style: solid;
	border-color: #021f30;
	background-color: rgba(27, 181, 186, .3);
	height:fit-content;
	color: rgb(255, 255, 255, .8);
	width: fit-content auto;
	height: fit-content;
	
} */
button.TOSPRIVACY{
	border-style: solid;
	border-color: #021f30;
	background-color: rgba(27, 181, 186, .3);
	text-align: center;
	align-self: center;
	
	font-weight: bold;
	cursor: pointer;
}

.topnav img {
	display:block;
	
	position: relative;
	/* flex:auto; */
	max-block-size: inherit;
	align-self: left;	
	height: 9lvh;
	max-inline-size: inherit;
	width: min-content;
	margin: 2%;
	overflow: hidden;
	margin-right: .5%;
	left:0;
	opacity: 1;
	overflow: hidden;
	/* display:inline-block; */
	transition: .5s ease;
	backface-visibility: hidden;

}


div.home {

	display: contents;
	padding: 10px 20px;
	background-color: #1bb5ba;
	color: rgb(182, 255, 251);
	border: 1px solid #021f30;
	border-radius: 14px;
	font-weight: bold;
	position: absolute;
	flex-direction: row-reverse;
	transform: translateX(-50%);
}

div.middle {
	transition: .5s ease;
	opacity: 0;
	position: absolute;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%)
}

.topnav.homeButtonContainer:hover img {
	opacity: 0.3;

}

.topnav.homeButtonContainer {
	display: flex;
	max-width:inherit;
	max-height: inherit;
	height: auto;
	float: left;
	justify-self: auto;
	justify-content: left;
	flex-direction: row;
	flex-flow: row;
	position: relative;
	top: 0;
	left: 0;
	
	
}

.topnav.homeButtonContainer:hover div.middle {
	opacity: 1;
}

div.infoLinks {
	text-shadow: #2C3333;
	background-color: rgba(106, 234, 239, 0.5);
	display: inline-block;
	border: 1px solid #ccc;
	box-sizing: border-box;
	


}
h3.contacts{
	text-align: left;
}
div.contactLinks {
	text-shadow: #2C3333;
	background-color: rgba(106, 234, 239, 0.5);
	display: inline-block;
	border: 1px solid #ccc;
	
	
	width: fit-content;
	padding: auto;
	text-wrap:balance;
	margin: 5%;
	padding-bottom: 10%;
	word-wrap: normal;
	scroll-padding-block-end: 8lvh;

}
div.registrationDiv{
	padding-bottom: 15lvh;
	text-align: center;
}
a.infoLink {
	text-decoration: underline;
	text-decoration-color: #2196ea;
	text-decoration-line: underline;
	text-underline-offset: .2rem;

}





/* .topnav button{
	float: right;
	background-color: #0E8388;
	color: #2E4F4F;
} */


.main-table {
	width: 100%;
	/* height:auto; */
	/* border-collapse: collapse; */
}

.message-cell {
	width: 35%;
}

/* .header-cell {
	background-color: #e72874;
	width: 10%;
	height: 3%;
	color: #2E4F4F;
	padding: 5%;
	text-align: center;
	border: 8px;
	padding: 1rem 1.2rem;
	border-radius: 6px;
	background-image: url("images/A+symbol.png");
	background-color: #0E8388;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;

	border-style: solid;
	border-color: #CBE4DE;
}  */

div.logcontent {
	align-self: left;
	text-align: center;
	margin: 6%;
	width: 35%;
	opacity: 0.8;
}

div.logstatement {
	align-self: right;
	text-align: center;
	width: auto;
	margin: 6%;
}

div.logcontent p {
	margin: 5%;
	font-weight: bold;
	color: #000000;
}

div.logcontent h1 {
	margin: 3%;
	font-weight: bold;
	color: #000000;
}

div.logcontent a {
	font-weight: bold;
	color: #000000;
}

div.logcontent button {
	margin: 1%;
	font-weight: bold;
	color: #000000;
}



.label {
	text-shadow: #2C3333;

	display: flex;
	width: 3%;
	/* Adjust this width based on your design */
	text-align: left;
	justify-self: left;
	justify-content: left;
	justify-items: left;
	font-weight: 900;
	/* margin-right: 10px; */
}

img.homeSymbol {
	width: 20%;
	height: 16%;
	border: none;
}

div.container {


	padding: 12px 40px;
	margin: 5%;
	background-color: rgb(0, 127, 129, .6);
	display: inline-block;
	border: 3px solid #9c9c9c;

	/* height: 75%;
	width:75%; */
}

div.homebuffer{
	width: 100%;
	height: 15lvh;
	display: inline-block;
	padding-bottom: 8lvh;
	
	
}
.main-content {
	padding: 2rem;
	
}

.intro-section img.sym {
	max-width: 100px;
	display: inline-block;
	align-content: center;
	margin: 0 auto;
}

/* Mission Section Styles */
.mission-section {
	width: 50%;
	display: inline-block;
	position: relative;
	justify-self: center;
	background-color:rgb(227,222,222,.8);
	
	padding: 2rem;
}

/* Video Section Styles */
.video-section {
	text-align: center;
	padding: 2rem;
	height: auto;
}

/* Services Section Styles */
.services-section {
	width: 50%;
	display: inline-block;
	position: relative;
	justify-self: center;
	background-color:rgb(227,222,222,.8);
	
	padding: 2rem;
}

/* Why Choose Us Section Styles */
.why-choose-us-section {
	width: 50%;
	display: inline-block;
	position: relative;
	justify-self: center;
	background-color:rgb(227,222,222,.8);
	
	padding: 2rem;
}

/* Contact Section Styles */
.contact-section {
	width: 50%;
	display: inline-block;
	position: relative;
	justify-self: center;
	background-color:rgb(227,222,222,.8);
	margin-bottom: 1%;
	padding: 2rem;
	
}

/* Donate Section Styles */
.donate-section {
	width: 50%;
	display: inline-block;
	position: relative;
	justify-self: center;
	background-color: #f9f9f9;
	padding: 2rem;
}

/* Footer Styles */
.footer {
	bottom: 0px;
	width: 100%;
	position: fixed;
	background-color: #333;
	color: #fff;
	text-align: center;
	scroll-padding-block-end: 5%;
	
	height: 13lvh;
	

}

iframe.youtube {
	display: inline-block;
	/* iframes are inline by default */
	/* Set height to 100% of the viewport height */
	width: 70%;
	/* Set width to 100% of the viewport width */
	border: none;
	/* Remove default border */
	background-image: url('images/hasmat.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	/* background-color: #CBE4DE;  */
	/* Just for styling */
	height: 65lvh;
}


div.calendar {
	margin-bottom: 10px;
	display: inline-block;
	position:static;
	height: 62vh;
	width: 80vw;
	
	text-shadow: #2C3333;
	background-color: rgba(255, 255, 255, 0.7);
	border: 1px solid #ccc;
	/* box-sizing: border-box; */


}

iframe.calendarIframe {
	/* display:; */
	position: relative;

	height: 100%;
	width: 100%;
}

a {
	color: #ffffff;
	text-decoration: none;
	font-weight: bold;
}

a.header {
	color: #2C3333;
}



div.paragraph {
	width: auto;
	height: 1%;
	padding-right: 15%;
	padding-left: 15%;
}

h2.para {
	padding-right: 20%;
	padding-left: 20%;
	color: #263A29;
}

h1 {
	color: #2C3333;
	font-weight: bold;
}

h2 {
	color: #2C3333;
	text-align: center;
	padding: 1%;
}

h3 {

	color: #2C3333;
	font-weight: bold;
	text-align: center;
	padding-right: 5%;
	padding-left: 5%;
}

h4 {
	color: #2C3333;
	font-weight: bold;
	text-align: center;
}



img {
	border: 1px solid #CBE4DE;
	border-radius: 2px;
	padding: 5px;
	height: 20%;
	width: 30%;
}

img.sym {
	height: 8%;
	width: 8%;
	position: relative;
	align-content: center;
}



.box {
	background: #0E8388;
	height: 80vh;
	vertical-align: top;
}

.content-cell {
	background-color: #fffcfcc8;
	padding: 10px;
	vertical-align: top;
}




iframe.rights {

	/* iframes are inline by default */
	/* Set height to 100% of the viewport height */
	width: 70%;
	/* Set width to 100% of the viewport width */
	border: none;
	/* Remove default border */
	background-image: url('images/hasmat.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;
	/* background-color: #CBE4DE;  */
	/* Just for styling */

	height: 86vh;
}

.google-map {
	padding-bottom: 4%;
	position: relative;
	align-content: center;

}

.google-map iframe {
	height: 78dvh;
	width: 62dvw;
	border: 2px;
	margin: auto;
	display: block;
	position: relative;
	/* position: absolute; */
}

.appointments-container {
	max-width: 100%;
	overflow-x: auto;
}

/* Style for the table */
.appointments-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 20px;
}

.appointments-table th,
.appointments-table td {
	border: 1px solid #ddd;
	padding: 8px;
	text-align: left;
}

.appointments-table th {
	background-color: #f2f2f2;
}

/* Style for the "No documents available" message */
.no-appointments-message {
	font-style: italic;
	color: #484747;
	margin-top: 20px;
}

/* * {
	box-sizing: border-box;
} */
/* 
.topnav {
	position:relative;
	top:0;
	left:0;
	
	display: inline-flex;
	 float:right; 
	flex-wrap:nowrap;
	flex-flow: row;
	flex-shrink: inherit;
	justify-content: flex-start;
	/* flex-wrap: nowrap;
	align-items: flex-start; 
	/* display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-flow: nowrap;
	flex-basis:content; 
	/* height: auto;
	
	width: 100%;
	align-items: left;
	justify-content: left;

	/* flex-direction: row;
	overflow: hidden; 
	background-image: url('images/background.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: cover;

} */
/* .topnav table{
	display: inline-flex;
	position: relative;
	width: 100%;
	flex-flow: row;
	flex-direction: row;
	flex: content;
	flex-basis: inherit;
	justify-content: space-between;
	height: auto;
	margin:auto;
	float: left;
	flex-direction: row;
	justify-self: left;
	flex-flow: row;
	
	left: 0;
}

.topnav td{
	justify-self: left;
	float: left;
	position: relative;
	size-adjust:inherit;
	display: flex;
	width:auto ;
	height: fit-content;
	align-self: left;
	list-style: none;
	margin: auto;
	padding: auto;
}
.topnav td.split{
	position: relative;
	height: fit-content;
	display: flex;

	flex-direction: column;
	width:auto ;

	flex-basis: inherit;
	justify-self:right;
	float: right;
	margin: auto;
	
}

.topnav tr{
	display:flex;
	flex-direction: column;
	margin: auto;
	justify-items: right;
	padding: auto;
	justify-self: right;
	align-items: center;
	justify-content: right;
	margin-right: 0;
	float: right;
	right:0;
	
}
.topnav button.forgot{
	position:relative;
	margin-right: 0;
	right: 0;
	float: right;
	justify-self: right;
	width:auto;
	justify-self: right;
	justify-self: end;
	
}
.topnav button {
	display: inline-block;
	/* flex-wrap: inherit; */
	/* font: inherit;
	align-self: flex-start;
	font-size-adjust: inherit;
	flex-grow: inherit;

	align-self: inherit;
	flex:content; 
	text-align: center;
	size-adjust:inherit;
	transition: all 0.3s ease;
	 padding: 14px 16px; 
	text-decoration: none;
	
	
	height: max-content;
	width: max-content;
	border-top-style: dotted;
	border-right-style: dotted;
	cursor: pointer;
	border-bottom-style: dotted;
	border-left-style: dotted;
	padding-right: 4%; 
	
	color: rgb(255, 255, 255, .8);
	 color: rgb(182, 255, 251,.8); 
	border-style: solid;
	border-color: #021f30;
	background-color: rgba(27, 181, 186, .3);
	list-style: none;
	margin-top: .75%;
	padding: auto;
	border-radius: 4%;
	text-align: center;
	align-self: left;
	
	 display: flex; 
	
	font-weight: bold;
	

}  */

/* .topnav img:hover {
	opacity: 0.5;
	cursor: pointer;
}

/* Change the color of links on hover */
/* .topnav button:hover {
	background-color: #6fc6c9;
	color: #2f0606;
}

.topnav button.split:hover {
	background-color: #6fc6c9;
	color: #2f0606;
}

/* Create a right-aligned (split) link inside the navigation bar */
/*
.topnav button.split {
	
	cursor: pointer;
	align-self: right;
	font-weight: bold;
	background-color: rgba(27, 181, 186, .3);
	color: rgb(255, 255, 255, .8);

}  */
/* .topnav button.split.bot {
	float: right;
	
	
	cursor: pointer;
	font-weight: bold;
	background-color: rgba(27, 181, 186, .3);
	color: rgb(255, 255, 255, .8);

} */

/* @media screen and (max-width: 600px) {
	topnav {
	  flex-direction: row;
	  text-align: center;
	}
	.nav-links {
		flex-direction: row;
	  }
	
	  .nav-links li {
		margin: 5px 0;
	  }
	} */

/* 
.nav-cell {
	width: 6%;
	height: 3%;
	padding: 3px;
	border: 7px;
	color: #41644A;
	border-style: solid;
	border-color: #CBE4DE;
	background-color: #0E8388;
	list-style: none;
	margin: 2% auto;
	padding: 1rem 1.2rem;
	border-radius: 6px;
	text-align: center;
	align-self: left;
} */




/* .nav-list li {
	padding: 10px;
	border-bottom: 1px solid #ccc;
} */


/* .nav-list {
	list-style: none;
	margin: 5% auto;
	display:flex; 
	border-top-style: dotted;
	border-right-style: solid;
	border-bottom-style: dotted;
	border-left-style: solid;

} */


/* .nav-list li:last-child {
	border-bottom: none;
}

.nav-list li a {
	text-decoration: none;
	color: #2E4F4F;
} */


/* 
td.apptInfo{
	text-align: right;
	align-self: right;
	align-content: right;
	text-align: right;
	justify-content: right;
	justify-self: right;
	justify-items: right;
}
tr.apptInfo{
	text-align: right;
	justify-content: right;
	justify-self: right;
	justify-items: right;
} */

/* input.apptInfo {
	
	display:flex;
  	justify-content: left;
	padding: .1% .3% ;
	margin: .2%;
	background-color: #ffffff;
	
	border-radius: 6px;

} */
/* div.input {
	
	align-content: center;
	justify-self: center;
	align-items: center;
	justify-content: center;
	padding: 12px 40px;
	margin: 5%;
	background-color: #0c9fb5;

} */



/* button.mission {
	background-color: #0E8388;
	color: #263A29;
	width: 23%;
	
	align-self: center;
	text-align: center;
	border-top-style: dotted;
	border-right-style: dotted;
	padding: .8rem 0rem;
	border-radius: 8px;
	border-bottom-style: dotted;
	border-left-style: dotted;
	bottom: 0px;
	position:fixed;
} */

/* div.apptInfo{
	text-align: left;
	flex: 1;
 	margin-bottom: 10px;
	padding: 12px 40px;
	margin: 5%;
	text-shadow: #2C3333;
	background-color:rgba(255, 255, 255, 0.5);
	display:inline-block;
	border: 1px solid #ccc;
	box-sizing: border-box;
} */